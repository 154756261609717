import firebase from "firebase";
import "firebase/auth";

// if (typeof window !== "undefined" && !firebase.apps.length) {
//     firebase.initializeApp({
//         apiKey: "AIzaSyC-Jna5XTa18AO8iGSK-zUZ-6SexWc3ZLU",
//         authDomain: "nec-bi-dev.firebaseapp.com",
//         databaseURL: "https://nec-bi-dev.firebaseio.com",
//         projectId: "nec-bi-dev",
//         storageBucket: "nec-bi-dev.appspot.com",
//         messagingSenderId: "678634054043",
//         appId: "1:678634054043:web:b654de9fbed3c5860a413e",
//     });

//     firebase.auth().languageCode = "vi";
// }

if (typeof window !== "undefined" && !firebase.apps.length) {
  firebase.initializeApp({
    apiKey : "AIzaSyDa7wNFJsE0IN-wmL_EafXT9fSpvnPpn4s" , 
    authDomain : "fnb-wifi-marketing.firebaseapp.com" , 
    projectId : "fnb-wifi-marketing" , 
    storageBucket : "fnb-wifi-marketing.appspot.com" , 
    messagingSenderId : "568220435149" , 
    appId : "1:568220435149:web:5c1832d81348258f789f84" , 
    measurementId : "G-GR9LC3JB2W"
  });
  firebase.auth().languageCode = "vi";
}

export { firebase };
