<template>
    <v-dialog v-model="isShowDialog" max-width="600" persistent>
        <v-card>
            <v-toolbar flat dark color="primary">
                <v-btn
                    icon
                    dark
                    @click="$emit('update:isShowDialog', !isShowDialog)"
                >
                    <v-icon>mdi-close</v-icon>
                </v-btn>
                <v-spacer />
                <v-toolbar-title class="text-h5 justify-center">{{
                    $t("verifyOTP.title")
                }}</v-toolbar-title>
                <v-spacer />
            </v-toolbar>
            <v-card-text>
                <v-row align="center">
                    <v-col cols="12" align="center">
                        <p class="pt-5">{{ $t("verifyOTP.OTPNote") }}</p>
                        <otp-input
                            ref="otpInputField"
                            class="otp-custom px-0 py-2"
                            v-model="otpCode"
                            :length="otpLength"
                            @valid="isTokenComplete"
                            :size="$vuetify.breakpoint.mobile ? 16 : 24"
                        ></otp-input>
                        <v-btn
                            block
                            class="primary mt-5"
                            :disabled="!isValid"
                            @click="onVerifyOTP"
                        >
                            {{ $t("verifyOTP.verify") }}
                        </v-btn>
                    </v-col>
                </v-row>
            </v-card-text>
        </v-card>
    </v-dialog>
</template>

<script>
import OTPInput from "@8bu/vue-otp-input";
import "@8bu/vue-otp-input/dist/vue-otp-input.css";
import Constants from "@/common/constant.js";

export default {
    name: "OTPDialog",
    components: {
        "otp-input": OTPInput,
    },
    props: {
        isShowDialog: {
            type: Boolean,
        },
    },
    data() {
        return {
            otpCode: "",
            otpLength: Constants.OTP_LENGTH,
            isValid: false,
        };
    },
    watch: {
        isShowDialog(newVal) {
            if (newVal === false) {
                this.otpCode = "";
            }
        },
    },
    methods: {
        isTokenComplete() {
            if (this.otpCode.length === Constants.OTP_LENGTH) {
                this.isValid = true;
            } else {
                this.isValid = false;
            }
        },
        onVerifyOTP() {
            this.$emit("onDialogConfirm", this.otpCode);
        },
    },
};
</script>